import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerAccordion, { CustomerCaseAccordionProps } from './roomstay-blog-customer-case-accordion';

export const RoomStayBlogCustomerAccordionBuilderConfig = {
    name: 'Customer Accordion Case',
    inputs: [
        {
            name: 'tag',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'subDescription',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'ctaButton',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'href',
                    type: 'string',
                },
            ],
        },
        {
            name: 'accordion',
            type: 'list',
            subFields: [
                {
                    name: 'icon',
                    type: 'file',
                    defaultValue: '',
                },
                {
                    name: 'text',
                    type: 'html',
                    defaultValue: '',
                },
                {
                    name: 'description',
                    type: 'richText',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseAccordionProps> = {
    component: RoomStayBlogCustomerAccordion,
    config: RoomStayBlogCustomerAccordionBuilderConfig,
};

export default builderConfig;

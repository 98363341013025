import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import Spinner from '../spinner/spinner';
import styles from './button.module.scss';

export type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonBookADemoProps extends Omit<HTMLButtonProps, 'type'> {
    link?: string;
    children: ReactNode;
    loading?: boolean;
    type?: 'primary' | 'secondary' | 'highty';
    size?: 'default' | 'medium' | 'small';
    theme?: 'black' | 'transparent' | 'purple';
    htmlType?: HTMLButtonProps['type'];
    hideShadow?: boolean;
    icon?: boolean;
    color?: 'white' | 'black';
    iconFillColor?: boolean;
    border?: 'transparent' | 'color' | 'purple';
    sectionId?: string;
    buttonName?: string;
}

const ButtonBookADemo: FC<ButtonBookADemoProps> = (props) => {
    const {
        children,
        link,
        className,
        icon = true,
        buttonName,
        loading,
        hideShadow,
        onClick,
        type = 'primary',
        size = 'default',
        color,
        iconFillColor,
        htmlType,
        border = false,
        theme,
        sectionId,
        ...buttonProps
    } = props;
    const router = useRouter();

    const handleScrollElementById = () => {
        const element = document.getElementById(sectionId || '');

        if (sectionId === element?.id) {
        }

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    };

    return (
        <button
            {...buttonProps}
            disabled={loading}
            className={classNames(
                styles.btn,
                styles[`btn-${type}`],
                styles[`btn-${size}`],
                styles[`btn-theme-${theme}`],
                styles[`btn-border-${border}`],
                styles[`btn-color-${color}`],
                className,
                {
                    [styles.shadowNone]: hideShadow,
                }
            )}
            onClick={(e) => {
                if (link) {
                    router.push(link);
                }
                if (sectionId) {
                    handleScrollElementById();
                } else onClick?.(e);
            }}
        >
            {icon && iconFillColor ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path d="M7.16663 16.2637H25.8333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.5 6.93036L25.8333 16.2637L16.5 25.597" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                    <g clipPath="url(#clip0)">
                        <path d="M6.6665 16.5H25.3332" stroke="url(#paint0_linear_15517_1999)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 7.1665L25.3333 16.4998L16 25.8332" stroke="url(#paint1_linear_15517_1999)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>

                    <defs>
                        <linearGradient id="paint0_linear_15517_1999" x1="6.6665" y1="17.5" x2="6.77334" y2="15.5057" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7C39FF" />
                            <stop offset="0.491191" stopColor="#BC1DB5" />
                            <stop offset="1" stopColor="#FF0068" />
                        </linearGradient>

                        <linearGradient id="paint1_linear_15517_1999" x1="16" y1="25.8332" x2="30.9333" y2="18.3665" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7C39FF" />
                            <stop offset="0.491191" stopColor="#BC1DB5" />
                            <stop offset="1" stopColor="#FF0068" />
                        </linearGradient>
                    </defs>
                </svg>
            )}

            {loading && <Spinner />}
            <span>{children || buttonName}</span>
        </button>
    );
};

export default ButtonBookADemo;

import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerCaseBlock, { CustomerCaseBlockProps } from './roomstay-blog-customer-case-block';

export const RoomStayBlogCustomerCaseBlockBuilderConfig = {
    name: 'Customer Case Block',
    inputs: [
        {
            name: 'avatar',
            type: 'file',
            defaultValue: '',
        },
        {
            name: 'textMain',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'image',
            type: 'file',
            defaultValue: '',
        },
        {
            name: 'backgroundColor',
            type: 'color',
            defaultValue: '',
        },
        {
            name: 'backgroundTop',
            type: 'color',
            defaultValue: '',
        },
        {
            name: 'backgroundBottom',
            type: 'color',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseBlockProps> = {
    component: RoomStayBlogCustomerCaseBlock,
    config: RoomStayBlogCustomerCaseBlockBuilderConfig,
};

export default builderConfig;

import React, { FC } from 'react';

import styles from './roomstay-blog-customer-case-block.module.scss';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';

export interface CustomerCaseBlockProps {
    avatar?: string;
    textMain?: string;
    title?: string;
    description: string;
    image?: string;
    backgroundTop?: string;
    backgroundBottom?: string;
    backgroundColor?: string;
}

const RoomStayBlogCustomerCaseBlock: FC<CustomerCaseBlockProps> = (props) => {
    const { avatar, textMain, title, description, image, backgroundBottom, backgroundTop } = props;

    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div className={styles.container}>
            <div className={styles.backgroundColor}></div>{' '}
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(190 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.background}
                style={{
                    // background: `red`,
                    maxHeight: `${windowWidth > 998 ? introHeight + 168 : windowWidth > 768 ? introHeight + 110 : introHeight + 90}px`,
                    transform: 'rotate(180deg)',
                }}
            />
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(171 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    // backgroundColor: `red`,
                    maxHeight: `${windowWidth > 768 ? introHeight + 140 : introHeight + 90}px`,
                    transform: 'rotate(180deg)',
                    marginTop: '0px',
                }}
            />
            <div className={styles.wrapperContent}>
                {avatar && (
                    <div className={styles.wrapperAvatar}>
                        <LazyLoadImage className={styles.wrapperImg} imageUrl={avatar} objectFit="cover" />
                    </div>
                )}
                <div className={styles.textMain} dangerouslySetInnerHTML={{ __html: textMain || '' }}></div>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>
                {image && (
                    <div className={styles.wrapperImage}>
                        <div className={styles.image}>
                            {' '}
                            <LazyLoadImage imageUrl={image} objectFit="cover" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RoomStayBlogCustomerCaseBlock;

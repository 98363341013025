import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerListCard, { CustomerCaseListCardProps } from './roomstay-blog-customer-case-list-card';

export const RoomStayBlogCustomerCaseListCardBuilderConfig = {
    name: 'Customer Case List Card',
    inputs: [
        {
            name: 'tag',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'cards',
            type: 'list',
            subFields: [
                {
                    name: 'number',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'number2',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'title',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'subTitle',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'description',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'background',
                    type: 'string',
                    defaultValue: '',
                },
            ],
        },
        {
            name: 'ctaButton',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'href',
                    type: 'string',
                },
            ],
        },
        {
            name: 'graphic',
            type: 'file',
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseListCardProps> = {
    component: RoomStayBlogCustomerListCard,
    config: RoomStayBlogCustomerCaseListCardBuilderConfig,
};

export default builderConfig;

import React, { FC } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import ButtonBookADemo from '../ButtonBookADemo';

import styles from './roomstay-blog-customer-case-hero.module.scss';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import classNames from 'classnames';
import { LazyLoadImage } from '../lazy-load-image';

export interface CustomerCaseHeroProps {
    subTitle?: string;
    title?: string;
    description?: string;
    ctaButton?: {
        text?: string;
        href?: string;
    };
    graphic?: string;
}

const RoomStayCustomerCaseHero: FC<CustomerCaseHeroProps> = (props) => {
    const { subTitle, title, description, ctaButton, graphic } = props;

    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();
    const [containerRef, { height }] = useElementSize();
    const { width } = useWindowSize();
    const isSmallMobile = windowWidth <= 578;
    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 991;

    return (
        <div ref={containerRef} className={styles.container}>
            {' '}
            <div className={styles.background}>
                <ClippedBox
                    configs={[
                        {
                            rad: isMobile ? Math.tan(60 / 375) : isTablet ? Math.tan(220 / 1920) : Math.tan(720 / 1920),
                            position: POSITION.BottomRight,
                        },
                    ]}
                    className={classNames(styles.background, styles.backgroundClippedBottom)}
                    style={{
                        backgroundColor: '#fff',
                        maxHeight: isSmallMobile ? 50 : isMobile ? 80 : isTablet ? 120 : 280,
                        transform: 'rotate(180deg)',
                        marginBottom: -1,
                    }}
                />
            </div>
            <div className={styles.wrapperContent}>
                <div className={styles.contentBoxLeft}>
                    <div className={styles.wrapperText}>
                        <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: subTitle || '' }}></div>
                        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></h1>
                        <div className={styles.imageMobile}>
                            <div className={styles.imgMobile}>{graphic && <LazyLoadImage imageUrl={graphic} alt="" objectFit="cover" />}</div>
                        </div>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>{' '}
                        {ctaButton?.text && (
                            <ButtonBookADemo
                                className={styles.button}
                                icon={false}
                                iconFillColor={false}
                                type="highty"
                                size="default"
                                htmlType="button"
                                sectionId={ctaButton?.href}
                            >
                                {ctaButton?.text || 'Book a Demo'}
                            </ButtonBookADemo>
                        )}
                    </div>
                </div>
                <div className={styles.contentBoxRight}>
                    <div className={styles.img}>{graphic && <LazyLoadImage imageUrl={graphic} alt="" objectFit="cover" />}</div>
                </div>
            </div>
        </div>
    );
};

export default RoomStayCustomerCaseHero;

import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerHero, { CustomerCaseHeroProps } from './roomstay-blog-customer-case-hero';

export const RoomStayBlogCustomerCaseBuilderConfig = {
    name: 'Customer Hero Case',
    inputs: [
        {
            name: 'subTitle',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'ctaButton',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'href',
                    type: 'string',
                },
            ],
        },
        {
            name: 'graphic',
            type: 'file',
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseHeroProps> = {
    component: RoomStayBlogCustomerHero,
    config: RoomStayBlogCustomerCaseBuilderConfig,
};

export default builderConfig;

import { BuilderConfig } from '@/types/builder';

import RoomstayBlogFooterLayout2, { RoomstayFooterLayout2Props } from './roomstay-blog-footer-layout-2';

export const RoomstayBlogFooterLayout2BuilderConfig = {
    name: 'Footer Layout 2',
    inputs: [
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'description',
            type: 'html',
        },
        {
            name: 'buttonText',
            type: 'string',
        },
        {
            name: 'titleForm',
            type: 'string',
        },
        {
            name: 'showTextFooter',
            type: 'boolean',
        },
        {
            name: 'showIconButton',
            type: 'boolean',
        },
        {
            name: 'headlineFooter',
            type: 'html',
        },
        {
            name: 'subHeadlineFooter',
            type: 'html',
        },
        {
            name: 'buttonNameFooter',
            type: 'string',
        },
        {
            name: 'buttonLinkFooter',
            type: 'string',
        },
        {
            name: 'idSection',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayFooterLayout2Props> = {
    component: RoomstayBlogFooterLayout2,
    config: RoomstayBlogFooterLayout2BuilderConfig,
};

export default builderConfig;

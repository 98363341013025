import { FC, useState } from 'react';

import ButtonBookADemo from '../ButtonBookADemo';
import { LazyLoadImage } from '../lazy-load-image';

import { useElementSize, useWindowSize } from 'usehooks-ts';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import styles from './roomstay-blog-customer-case-accordion-2.module.scss';

export interface CustomerCaseAccordion2Props {
    tag?: string;
    title?: string;
    description?: string;
    subDescription?: string;
    ctaButton?: {
        text?: string;
        href?: string;
    };

    cards: {
        number?: string;
        titleCard?: string;
        descriptionCard?: string;
        backgroundCard?: string;
        backgroundDivider?: string;
        descriptionCard2?: string;

        accordion: {
            icon?: string;
            text?: string;
            description?: string;
            backgroundIcon?: string;
            colorArrow?: string;
        }[];
    }[];
}

const RoomStayBlogCustomerCaseAccordion: FC<CustomerCaseAccordion2Props> = (props) => {
    const { tag, title, description, ctaButton, cards } = props;
    const [expandedIndices, setExpandedIndices] = useState<(number | null)[]>(cards.map(() => null));
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 991;

    const handleToggle = (cardIndex: number, accordionIndex: number) => {
        setExpandedIndices((prev) => {
            const newIndices = [...prev];
            newIndices[cardIndex] = newIndices[cardIndex] === accordionIndex ? null : accordionIndex;
            return newIndices;
        });
    };

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: isMobile ? Math.atan(110 / 1920) : isTablet ? Math.atan(101 / 1920) : Math.atan(101 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.background}
                style={{
                    backgroundColor: '#1C1624',
                    maxHeight: `${windowWidth > 998 ? introHeight + 230 : introHeight + 70}px`,
                    transform: 'rotate(180deg)',
                }}
            />

            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(171 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    backgroundColor: 'red',
                    maxHeight: `${windowWidth > 768 ? introHeight + 240 : introHeight + 90}px`,
                    transform: 'rotate(180deg)',
                    marginTop: '0px',
                }}
            />
            <div className={styles.backgroundColor}></div>

            <div className={styles.wrapperBox}>
                <div className={styles.boxLeft}>
                    <div className={styles.wrapperTag}>
                        <div className={styles.tag} dangerouslySetInnerHTML={{ __html: tag || '' }}></div>
                    </div>
                    <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></h2>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>
                    {ctaButton?.text && (
                        <ButtonBookADemo className={styles.button} icon={false} iconFillColor={false} type="highty" size="default" htmlType="button" sectionId={ctaButton?.href}>
                            {ctaButton?.text || 'Book a Demo'}
                        </ButtonBookADemo>
                    )}
                </div>

                <div className={styles.boxRight}>
                    {cards?.map((card, cardIndex) => {
                        return (
                            <div key={cardIndex}>
                                <div className={styles.boxCard} style={{ backgroundColor: `${card.backgroundCard || 'white'}` }}>
                                    <div className={styles.wrapperCardContent}>
                                        <div className={styles.cardNumber} dangerouslySetInnerHTML={{ __html: card.number || '' }}></div>
                                        <div className={styles.cardText}>
                                            <h3 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: card.titleCard || '' }}></h3>
                                            <div className={styles.cardDescription} dangerouslySetInnerHTML={{ __html: card.descriptionCard || '' }}></div>
                                        </div>
                                    </div>

                                    <div className={styles.cardDivider} style={{ backgroundColor: `${card.backgroundDivider}` }}></div>

                                    {card?.accordion?.map((item, accordionIndex) => {
                                        const isExpanded = expandedIndices[cardIndex] === accordionIndex;
                                        return (
                                            <div
                                                key={accordionIndex}
                                                className={`${styles.wrapperItem} ${isExpanded ? styles.expanded : ''}`}
                                                onClick={() => handleToggle(cardIndex, accordionIndex)}
                                            >
                                                <div className={styles.wrapperHeader}>
                                                    <div className={styles.headerLeft}>
                                                        <div className={styles.icon} style={{ backgroundColor: `${item.backgroundIcon || 'white'} ` }}>
                                                            {item.icon && <LazyLoadImage className={styles.image} imageUrl={item.icon} alt="" objectFit="cover" />}
                                                        </div>

                                                        <h4 className={styles.text} dangerouslySetInnerHTML={{ __html: item.text || '' }}></h4>
                                                    </div>

                                                    <div className={styles.headerRight}>
                                                        <div className={`${styles.arrow} ${isExpanded ? styles.rotated : ''}`}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M1.50635 6.91016L0.504394 7.91211L7.86963 15.2686L15.2261 7.91211L14.2329 6.91016L8.59033 12.5527L8.59033 0.731445L7.18408 0.731445L7.18408 12.5967L1.50635 6.91016Z"
                                                                    fill={`${item.colorArrow || 'white'}`}
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`${styles.wrapperDescription} ${isExpanded ? styles.show : ''}`}>
                                                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description || '' }}></div>
                                                </div>

                                                <div className={styles.dividerAccordion} style={{ backgroundColor: `${card.backgroundDivider}` }}></div>
                                            </div>
                                        );
                                    })}

                                    <div className={styles.cardDescription2} dangerouslySetInnerHTML={{ __html: card.descriptionCard2 || '' }}></div>
                                </div>
                            </div>
                        );
                    })}

                    {ctaButton?.text && (
                        <ButtonBookADemo className={styles.button} icon={false} iconFillColor={false} type="highty" size="default" htmlType="button" sectionId={ctaButton?.href}>
                            {ctaButton?.text || 'Book a Demo'}
                        </ButtonBookADemo>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogCustomerCaseAccordion;

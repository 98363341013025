import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperOptions } from 'swiper';

import styles from './roomstay-blog-customer-case-list-card-2.module.scss';

import classNames from 'classnames';
import Button from '../button';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';

const options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    allowTouchMove: true,
    noSwiping: false,
    freeMode: true,
    breakpoints: {
        992: {
            watchOverflow: true,
        },
    },
};

export interface CustomerCaseListCard2Props {
    tag?: string;
    title?: string;
    description?: string;

    cards?: {
        background?: string;
        titleCard?: string;
        descriptionCard?: string;
        icon?: string;
    }[];
}

const RoomStayBlogCustomerCaseListCard: FC<CustomerCaseListCard2Props> = (props) => {
    const { tag, title, description, cards } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();
    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.backgroundColor}></div>

            <ClippedBox
                configs={[
                    {
                        rad: windowWidth > 768 ? Math.atan(71 / 1920) : Math.atan(100 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundClipped}
                style={{
                    backgroundColor: '#00D7E1',
                    maxHeight: `${windowWidth > 768 ? introHeight + 120 : introHeight + 120}px`,
                    transform: 'rotate(180deg)',
                }}
            />
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(221 / 1920),
                        position: POSITION.TopLeft,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    background: '#00D7E1',
                    maxHeight: `${windowWidth > 768 ? introHeight + 490 : introHeight + 120}px`,
                    transform: 'rotate(0deg)',
                    marginTop: '0px',
                }}
            />
            <div className={styles.wrapperTag}>
                <div className={styles.tag} dangerouslySetInnerHTML={{ __html: tag || '' }}></div>
            </div>

            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></h2>

            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>

            <div className={styles.wrapperCard}>
                <div className={styles.box}>
                    <Swiper {...options}>
                        {cards?.map((card, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={classNames(styles.item)} style={{ backgroundColor: `${card.background}` }}>
                                        {card.icon && (
                                            <div className={styles.icon}>
                                                <LazyLoadImage className={styles.wrapperImg} imageUrl={card.icon} objectFit="cover" />
                                            </div>
                                        )}
                                        <h3 className={styles.titleCard} dangerouslySetInnerHTML={{ __html: card.titleCard || '' }}></h3>
                                        <div className={styles.descriptionCard} dangerouslySetInnerHTML={{ __html: card.descriptionCard || '' }}></div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogCustomerCaseListCard;

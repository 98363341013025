import { FC } from 'react';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import classNames from 'classnames';
import { useWindowSize } from 'usehooks-ts';
import LazyLoadImage from '../lazy-load-image/lazy-load-image';
import RoomstaySemHelpdeskForm from '../roomstay-blog-sem-helpdesk-form/roomstay-blog-sem-helpdesk-form';
import styles from './roomstay-blog-sem-hero.module.scss';

export interface BlogSemHeroProps {
    title: string;
    subTitle: string;
    description: string;
    image: string;
    backgroundColor: string;
    titleForm: string;
    showImage?: boolean;
    buttonText?: string;
    hideClipped?: boolean;
}

const RoomstayBlogSemHero: FC<BlogSemHeroProps> = (props) => {
    const { title, subTitle, description, image, buttonText, titleForm, showImage, hideClipped } = props;

    const { width: windowWidth } = useWindowSize();

    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 1200;
    const isDesktop = windowWidth <= 1920;

    return (
        <div className={styles.container}>
            {!hideClipped && (
                <ClippedBox
                    configs={[
                        {
                            rad: isMobile ? Math.tan(101 / 375) : isTablet ? Math.tan(431 / 1920) : isDesktop ? Math.tan(320 / 1920) : Math.tan(190 / 1920),

                            position: POSITION.BottomRight,
                        },
                    ]}
                    className={classNames(styles.background, styles.backgroundClippedBottom)}
                    style={{
                        backgroundColor: `${props.backgroundColor ?? '#1C1624'}`,
                        maxHeight: isMobile ? 300 : isTablet ? 300 : 420,
                        transform: 'rotate(180deg)',
                        marginTop: -1,
                    }}
                />
            )}

            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: title || '',
                        }}
                    />
                    <div
                        className={styles.subTitle}
                        dangerouslySetInnerHTML={{
                            __html: subTitle || '',
                        }}
                    />
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: description || '',
                        }}
                    ></div>
                </div>
                {showImage ? (
                    <LazyLoadImage className={styles.image} imageUrl={image}></LazyLoadImage>
                ) : (
                    <div className={styles.wrapperForm}>
                        <RoomstaySemHelpdeskForm titleForm={titleForm} fontSizeTitle="default" buttonText={buttonText} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RoomstayBlogSemHero;

import React, { FC } from 'react';

import styles from './roomstay-blog-customer-case-image.module.scss';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import classNames from 'classnames';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';

export type CustomerCaseImageProps = {
    image: string;
    backgroundTop: string;
    backgroundBottom: string;
};

const RoomStayBlogCustomerCaseImage: FC<CustomerCaseImageProps> = (props) => {
    const { image, backgroundBottom, backgroundTop } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(81 / 1920),
                        position: POSITION.TopLeft,
                    },
                ]}
                className={styles.background}
                style={{
                    backgroundColor: `${backgroundTop}`,
                    maxHeight: `${windowWidth > 768 ? introHeight + 120 : introHeight + 40}px`,
                    transform: 'rotate(180deg)',
                }}
            />

            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(81 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    backgroundColor: '#fff',
                    maxHeight: `${windowWidth > 768 ? introHeight + 70 : introHeight + 40}px`,
                    transform: 'rotate(180deg)',
                    marginTop: '0px',
                }}
            />
            <LazyLoadImage className={styles.wrapperImg} imageUrl={image} objectFit="cover" />
        </div>
    );
};

export default RoomStayBlogCustomerCaseImage;

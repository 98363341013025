import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerCaseImage, { CustomerCaseImageProps } from './roomstay-blog-customer-case-image';

export const RoomStayBlogCustomerCaseImageBuilderConfig = {
    name: 'Customer Case Image',
    inputs: [
        {
            name: 'image',
            type: 'file',
            defaultValue: '',
        },
        {
            name: 'backgroundTop',
            type: 'color',
            defaultValue: '',
        },
        {
            name: 'backgroundBottom',
            type: 'color',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseImageProps> = {
    component: RoomStayBlogCustomerCaseImage,
    config: RoomStayBlogCustomerCaseImageBuilderConfig,
};

export default builderConfig;

import { FC } from 'react';

import classNames from 'classnames';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import styles from './roomstay-blog-use-case-hero.module.scss';

export interface RoomstayBlogHeroUseCasesProps {
    heading: string;
    title: string;
    description: string;
    image?: string;
}
const RoomstayBlogHeroUseCases: FC<RoomstayBlogHeroUseCasesProps> = (props) => {
    const { heading, title, description, image } = props;

    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();
    const [containerRef, { height }] = useElementSize();
    const { width } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.background}>
                <ClippedBox
                    configs={[]}
                    className={styles.background}
                    style={{
                        backgroundColor: '#F9F9FA',
                        maxHeight: `${height / 2}px`,
                    }}
                />
                <ClippedBox
                    configs={[
                        {
                            rad: Math.atan(190 / 1920),
                            position: POSITION.BottomRight,
                        },
                    ]}
                    className={classNames(styles.background, styles.backgroundClipped)}
                    style={{
                        backgroundColor: '#F9F9FA',
                        maxHeight: `${windowWidth > 768 ? introHeight + 380 + 156 : introHeight + 530 + 38}px`,
                        marginTop: '-2px',
                    }}
                />
            </div>

            <div className={styles.wrapContainer}>
                <div className={styles.wrapImg}>
                    <div className={styles.img}>{image && <LazyLoadImage imageUrl={image} alt="" objectFit="cover" />}</div>
                </div>

                <div className={styles.gridContent}>
                    <div className={styles.wrapContent}>
                        <div className={styles.heading}>{heading}</div>
                        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h1>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
                    </div>
                    <div className={styles.box}></div>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogHeroUseCases;

import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperOptions } from 'swiper';

import classNames from 'classnames';
import ButtonBookADemo from '../ButtonBookADemo';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import styles from './roomstay-blog-customer-case-list-card.module.scss';

const options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    allowTouchMove: true,
    noSwiping: false,
    freeMode: true,
    breakpoints: {
        992: {
            watchOverflow: true,
        },
    },
};

export interface CustomerCaseListCardProps {
    tag?: string;
    title?: string;

    cards?: {
        background?: string;
        number?: string;
        number2?: string;
        subTitle?: string;
        title?: string;
        description?: string;
        fontSizeTitleMobile?: number;
    }[];

    ctaButton?: {
        text?: string;
        href?: string;
    };
}

const RoomStayBlogCustomerCaseListCard: FC<CustomerCaseListCardProps> = (props) => {
    const { tag, title, cards, ctaButton } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: windowWidth > 768 ? Math.atan(71 / 1920) : Math.atan(191 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundClipped}
                style={{
                    backgroundColor: '#fff',
                    maxHeight: `${windowWidth > 768 ? introHeight + 87 : introHeight + 60}px`,
                    transform: 'rotate(180deg)',
                }}
            />

            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(121 / 1920),
                        position: POSITION.TopLeft,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    background: `linear-gradient(65deg, rgb(124, 57, 255) -43.79%, rgb(188, 29, 181) 34.45%, rgb(255, 0, 104) 98.93%)`,
                    maxHeight: `${windowWidth > 768 ? introHeight + 140 : introHeight + 90}px`,
                    transform: 'rotate(0deg)',
                    marginTop: '0px',
                }}
            />
            <div className={styles.wrapperTag}>
                <div className={styles.tag} dangerouslySetInnerHTML={{ __html: tag || '' }}></div>
            </div>

            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></h2>

            <div className={styles.wrapperCard}>
                <div className={styles.box}>
                    <Swiper {...options}>
                        {cards?.map((card, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={classNames(styles.item)} style={{ background: `${card.background}` }}>
                                        {card.number && <div className={styles.number} dangerouslySetInnerHTML={{ __html: card.number }}></div>}
                                        {card.number2 && <div className={styles.number2} dangerouslySetInnerHTML={{ __html: card.number2 }}></div>}
                                        {card.title && <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: card.title }}></h3>}
                                        {card.subTitle && <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: card.subTitle }}></div>}
                                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: card.description || '' }}></div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>

            {ctaButton?.text && (
                <ButtonBookADemo className={styles.button} icon={false} iconFillColor={false} type="highty" size="default" htmlType="button" sectionId={ctaButton?.href}>
                    {ctaButton?.text || 'Book a Demo'}
                </ButtonBookADemo>
            )}
        </div>
    );
};

export default RoomStayBlogCustomerCaseListCard;

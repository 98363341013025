import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerAccordion2, { CustomerCaseAccordion2Props } from './roomstay-blog-customer-case-accordion-2';

export const RoomStayBlogCustomerAccordion2BuilderConfig = {
    name: 'Customer Accordion Case 2',
    inputs: [
        {
            name: 'tag',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'ctaButton',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'href',
                    type: 'string',
                },
            ],
        },
        {
            name: 'cards',
            type: 'list',
            subFields: [
                {
                    name: 'number',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'titleCard',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'descriptionCard',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'backgroundCard',
                    type: 'color',
                    defaultValue: '',
                },
                {
                    name: 'backgroundDivider',
                    type: 'color',
                    defaultValue: '',
                },
                {
                    name: 'accordion',
                    type: 'list',
                    subFields: [
                        {
                            name: 'icon',
                            type: 'file',
                            defaultValue: '',
                        },
                        {
                            name: 'backgroundIcon',
                            type: 'color',
                            defaultValue: '',
                        },
                        {
                            name: 'colorArrow',
                            type: 'color',
                            defaultValue: '',
                        },

                        {
                            name: 'text',
                            type: 'richText',
                            defaultValue: '',
                        },
                        {
                            name: 'description',
                            type: 'richText',
                            defaultValue: '',
                        },
                    ],
                },
                {
                    name: 'descriptionCard2',
                    type: 'richText',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseAccordion2Props> = {
    component: RoomStayBlogCustomerAccordion2,
    config: RoomStayBlogCustomerAccordion2BuilderConfig,
};

export default builderConfig;

import { FC, forwardRef, ForwardRefRenderFunction } from 'react';

import styles from './text-intro.module.scss';

export interface TexIntroProps {
    title: string;
    description?: string;
}

const TextIntro: ForwardRefRenderFunction<HTMLDivElement, TexIntroProps> = (props, ref) => {
    const { title, description } = props;

    return (
        <div ref={ref} className={styles.container}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.description}>{description}</div>
        </div>
    );
};

export default forwardRef(TextIntro);

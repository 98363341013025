import { FC, useState } from 'react';

import Button from '../button';
import { LazyLoadImage } from '../lazy-load-image';
import styles from './roomstay-blog-customer-case-accordion.module.scss';

export interface CustomerCaseAccordionProps {
    tag?: string;
    title?: string;
    description?: string;
    subDescription?: string;
    ctaButton?: {
        text?: string;
        href?: string;
    };

    accordion: {
        icon: string;
        text?: string;
        description?: string;
    }[];
}

const RoomStayBlogCustomerCaseAccordion: FC<CustomerCaseAccordionProps> = (props) => {
    const { tag, title, description, subDescription, ctaButton, accordion } = props;

    const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  
    const handleToggle = (index: number) => {
        setExpandedIndices((prevIndices) =>
            prevIndices.includes(index)
                ? prevIndices.filter((i) => i !== index)
                : [...prevIndices, index]
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapperBox}>
                <div className={styles.boxLeft}>
                    <div className={styles.wrapperTag}>
                        <div className={styles.tag} dangerouslySetInnerHTML={{ __html: tag || '' }}></div>
                    </div>
                    <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></h2>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>
                    <div className={styles.subDescription} dangerouslySetInnerHTML={{ __html: subDescription || '' }}></div>
                </div>

                <div className={styles.boxRight}>
                    {accordion.map((item, index) => {
                                   const isExpanded = expandedIndices.includes(index);

                        return (
                            <div key={index} className={`${styles.wrapperItem} ${isExpanded ? styles.expanded : ''}`} onClick={() => handleToggle(index)}>
                                <div className={styles.wrapperHeader}>
                                    <div className={styles.headerLeft}>
                                        <div className={styles.icon}>
                                            <LazyLoadImage className={styles.image} imageUrl={item.icon} alt="" objectFit="cover" />
                                        </div>

                                        <h3 className={styles.text} dangerouslySetInnerHTML={{ __html: item.text || '' }}></h3>
                                    </div>

                                    <div className={styles.headerRight}>
                                        <div className={`${styles.arrow} ${isExpanded ? styles.rotated : ''}`}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.50635 6.91016L0.504394 7.91211L7.86963 15.2686L15.2261 7.91211L14.2329 6.91016L8.59033 12.5527L8.59033 0.731445L7.18408 0.731445L7.18408 12.5967L1.50635 6.91016Z"
                                                    fill="#FF0068"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.wrapperDescription} ${isExpanded ? styles.show : ''}`}>
                                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description || '' }}></div>
                                </div>
                            </div>
                        );
                    })}

                    {ctaButton?.text && (
                        <Button className={styles.button} type="primary" theme="default" size="default" htmlType="button" link={ctaButton?.href}>
                            {ctaButton?.text || 'Book a Demo'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogCustomerCaseAccordion;

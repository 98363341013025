import { BuilderConfig } from '@/types/builder';

import RoomStayBlogCustomerListCard2, { CustomerCaseListCard2Props } from './roomstay-blog-customer-case-list-card-2';

export const RoomStayBlogCustomerCaseListCard2BuilderConfig = {
    name: 'Customer Case List Card 2',
    inputs: [
        {
            name: 'tag',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'cards',
            type: 'list',
            subFields: [
                {
                    name: 'icon',
                    type: 'file',
                },
                {
                    name: 'titleCard',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'descriptionCard',
                    type: 'richText',
                    defaultValue: '',
                },
                {
                    name: 'background',
                    type: 'color',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<CustomerCaseListCard2Props> = {
    component: RoomStayBlogCustomerListCard2,
    config: RoomStayBlogCustomerCaseListCard2BuilderConfig,
};

export default builderConfig;
